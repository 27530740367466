//require('./bootstrap');
require('alpinejs');
import * as FilePond from 'filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

window.FilePond = FilePond;
window.FilePondPluginImagePreview = FilePondPluginImagePreview;
window.FilePondPluginFileValidateType = FilePondPluginFileValidateType;
window.FilePondPluginFileValidateSize = FilePondPluginFileValidateSize;
window.ClassicEditor = ClassicEditor;

/* import Vue from 'vue';
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.component(
    "quiz-questions",
    require("./vue/QuizQuestions.vue").default
);

Vue.use(VueAxios, axios);

const app = new Vue({
    el: "#app",
});
 */